import { useRouter } from 'next/router';
import { Container, Row, Col } from 'reactstrap';
import StyledContainer from '@src/components/StyledContainer';
import { getCSWorkingTimes } from '@src/utils';
import { useI18n } from '@src/locales';
import * as Styles from './info.styled';

const Info = () => {
    const { locale } = useRouter();
    const { t } = useI18n();

    const siteLang = locale.slice(2, 4);

    return (
        <Styles.Info className="info_footer">
            <StyledContainer fluid>
                <Row className="align-items-center position-relative">
                    <Styles.Service lg="5" className="pb-1 pb-md-1 service_sales">
                        <div>
                            <h4 className="sales-text-footer">{t('flyerCS')}</h4>
                            <small>{t('alwaysListening')}</small>
                        </div>
                    </Styles.Service>
                    <Col xs="0" md="0" lg="7" className="customer_service_CTA">
                        <Container fluid>
                            <Row>
                                <Styles.Column xs="12" md="4" className="pr-0 pb-1 pb-sm-1 left_info">
                                    <div>
                                        <h5>{siteLang === 'fr' ? t('csPhoneFR') : t('csPhoneNL')}</h5>
                                        <span>{t('serviceTimes', getCSWorkingTimes(locale))}</span>
                                    </div>
                                </Styles.Column>
                                <Styles.Column xs="12" md="4" className="pr-0 pb-1 pb-sm-1 centre_info">
                                    <div>
                                        <h5>{t('infoMail', { reseller: process.env.NEXT_PUBLIC_SHOP_NAME })}</h5>
                                        <span>{t('feedBackTime')}</span>
                                    </div>
                                </Styles.Column>
                                <Styles.Column xs="12" md="4" className="pr-0 right_info">
                                    <div>
                                        <h5>{t('followUs')}</h5>
                                        {
                                            process.env.NEXT_PUBLIC_SHOP_NAME === 'gadgets'
                                                ? <a href={`/${locale}/${t('subscribeNewsletter.link')}`}><span>{t('stayInformedOfOurLatestOffers')}</span></a>
                                                : <a href={`/${locale}/${t('subscribeNewsletterOffice.link')}`}><span>{t('stayInformedOfOurLatestOffers')}</span></a>
                                        }
                                    </div>
                                </Styles.Column>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </StyledContainer>
        </Styles.Info>
    );
};

export default Info;
