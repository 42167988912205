import styled from 'styled-components';
import { Col } from 'reactstrap';
import { em } from 'polished';

export const Info = styled.div`
    background-color: var(--primary);
    padding: 1.4em 0;
`;

export const Figure = styled.figure`
    position: absolute;
    left: 20.5%;
    bottom: -1.4em;
    margin: 0;

    > span {
        display: block !important;
    }
`;

export const Service = styled(Col)`
    color: #fff;
    position: relative;
    padding-right: 15em;

    @media (max-width: 989px) {
        padding-right: 0;
    }

    h4 {
        font-weight: 700;
        margin: 0;
        padding: 0;
    }

    small {
        font-family: 'Shadows Into Light';
        font-size: 20px;
    }
`;

export const Column = styled(Col)`
    position: relative;
    
    > i {
        position: absolute;
        left: 5px;
        top: 0;
        color: #fff;
        font-size: 30px;
    }

    > div {
        padding-left: 3em;
        color: #fff;
        
        > h5 {
            font-size: ${em('16px')};
            font-weight: 700;
            margin: 0;
        }

        > a, > a:hover {
                color: #fff;
                font-size: ${em('13px')};
                font-weight: 300;
                text-decoration: underline;
        }

        &:first-letter {
            text-transform: uppercase;
        }

        > span {
            font-size: ${em('13px')};
            font-weight: 300;
        }
    }

    &:nth-child(1) {
        > div{
            background: url(${`${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/headphone02.png`}) no-repeat left 3px;
            @media (max-width: 767px) {
                background-size: 30px;
            }
        }
    }

    &:nth-child(2) {
        > div {
            padding-left: 3em;
            background: url(${`${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/mail02.png`}) no-repeat left 3px;
            @media (max-width: 767px) {
                background-size: 30px;
                padding-left: 3em;
            }
        }
    }

    &:nth-child(3) {
        > div{
            padding-left: 0;
            background-size: 41px;
            @media (max-width: 767px) {
                background-size: 30px;
                padding-left: 3em;
            }
        }
    }
`;

export const Media = styled.div`
    background-color: ${({ mobile }) => (mobile ? '#fff' : 'transparent')};
    padding: ${({ mobile }) => (mobile ? '1em 4%' : '0')};

    a {
        display: inline-block;
        margin-right: 1em;
    }
`;
